import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
// import { MatExpansionModule } from '@angular/material';
import {MatExpansionModule} from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpRequestInterceptor } from './services/http-intercepter.service';
import { HttpModule } from '@angular/http';
import { DialogflowService } from './services/dialogflow.service';
import { StorageService } from './services/localStorage.service';
import { DebounceDirective } from './directives/debounce.directive';
// import { EmbedVideo } from 'ngx-embed-video';

// import { HomeComponent } from './pages/home/home.component';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
    HttpModule,
    MatCardModule,
    BrowserAnimationsModule,
    NgbModule,
    MatExpansionModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    // EmbedVideo.forRoot(),

  ],  
  exports:[
    MatExpansionModule,
  ],
  
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    StorageService,
    Title,DialogflowService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
