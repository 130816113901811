
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { PreloadSelectedModulesList } from './services/preload_selected_modules_list';
// import { AuthGuard } from './guards/auth-guard.service';

const routes: Routes = [

  // place: canActivate: [AuthGuard] when connecting full app

  // Module for recruiters, replica of android App
  { path: 'recruiter',
   loadChildren: () => import('./pages/recruiter-app/recruiter-app.module').then(m => m.RecruiterAppModule),
  //  pathMatch: 'full'
  },
  { path: 'founder',
   loadChildren: () => import('./pages/founder-app/founders.module').then(m => m.FoundersModule),
  //  pathMatch: 'full'
  },
  // { path: 'traction',
  //  loadChildren: './pages/traction-app/traction-app.module#TractionAppModule',
  // //  pathMatch: 'full'
  // },
 // web module for all users
 { path: '', loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)},
  // { path: '', component: HomeComponent },
  { path: '**', redirectTo: 'dashboard' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabled' })],  //initialNavigation: 'enabled' for removing flick
  exports: [RouterModule],
})
export class AppRoutingModule {
}
