export const environment = {
  production: true,
  // baseUrl: 'http://34.74.176.170:5000/api',
  baseUrl: 'https://dnnae-api-prod-bdhweedyzq-uc.a.run.app',
  // baseUrl: 'https://dnnaemail.com/api',
  founderBaseUrl: 'http://34.73.147.239:5500',
  socket: 'http://localhost:5000/api/v1',
  dialogFlowToken: '20fbae890e1c412d9a06319f11a26e14',
  // nlpUrl:'https://ml.dnnae.com',
  nlpUrl:'https://www.dnnae.com/nerServer',
  // nlpUrl: 'https://dnnaemail.com/nerServer',
  classifierUrl:'https://www.dnnae.com/classificationServer',
  // classifierUrl: 'https://dnnaemail.com/classificationServer',
  mailServer: 'http://34.217.69.31',
  // mapApiKey: 'AIzaSyCaKbVhcX_22R_pRKDYuNA7vox-PtGaDkI',
  mapApiKey: 'AIzaSyA6OjPEAWYRbc39TNABYSGx6p3bO-CzD2A',
  microsoftClientId: '1dc7ff8c-d720-41d6-9489-8d213633ec90',
  googleClientId: '770135419608-tav1h5kiho8aqojju7i76f87kuos3ng6.apps.googleusercontent.com',

  // googleLogin:'https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?prompt=login&response_type=code&redirect_uri=https%3A%2F%2Fdnnae-api-bdhweedyzq-uc.a.run.app%2Fgoogle%2Fcallback&scope=profile%20email&client_id=770135419608-tav1h5kiho8aqojju7i76f87kuos3ng6.apps.googleusercontent.com&flowName=GeneralOAuthFlow',
  // microsoftLogin:'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?response_type=code&redirect_uri=https%3A%2F%2Fdnnae-api-bdhweedyzq-uc.a.run.app%2Fmicrosoft%2Fcallback&scope=User.Read&client_id=1dc7ff8c-d720-41d6-9489-8d213633ec90&prompt=login'
 
  // For prod
  googleLogin:'https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?prompt=select_account&response_type=code&redirect_uri=https%3A%2F%2Fdnnae-api-prod-bdhweedyzq-uc.a.run.app%2Fgoogle%2Fcallback&scope=profile%20email&client_id=770135419608-tav1h5kiho8aqojju7i76f87kuos3ng6.apps.googleusercontent.com&flowName=GeneralOAuthFlow',
  microsoftLogin:'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?response_type=code&redirect_uri=https%3A%2F%2Fapi.dnnae.com%2Fmicrosoft%2Fcallback&scope=User.Read&client_id=1dc7ff8c-d720-41d6-9489-8d213633ec90&prompt=login'
  // DNNae.com \/
  // googleLogin:'https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?prompt=select_account&response_type=code&redirect_uri=https%3A%2F%2Fwww.dnnae.com%2Fgoogle%2Fcallback&scope=profile%20email&client_id=770135419608-tav1h5kiho8aqojju7i76f87kuos3ng6.apps.googleusercontent.com&flowName=GeneralOAuthFlow'
  // microsoftLogin:'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?response_type=code&redirect_uri=https%3A%2F%2Fwww.dnnae.com%2Fmicrosoft%2Fcallback&scope=User.Read&client_id=1dc7ff8c-d720-41d6-9489-8d213633ec90&prompt=login'
};
